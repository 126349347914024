<template>
  <div>
    <div class="container mt-5">
      <h3
        data-aos-duration="2000"
        data-aos="fade-right"
        class="mb-4 header-spacing moving-gradient-text fw-bolder"
      >
        CLIENT RELATIONSHIP MANAGEMENT
      </h3>

      <div class="row my-5">
        <div class="col my-auto pe-md-5">
          <img src="@//assets/images/pages/crm-stuff.png" width="100%" alt="" />
        </div>

        <div class="col-md-5 my-auto">
          <p>
            At
            <strong>friday<span class="text-pink">collective</span></strong>
            we build tailored client & customer relationship management
            platforms that fit your every business need.
          </p>
          <p class="mb-0">
            Working with you to understand every part of your business and how a
            portal could improve sales, productivity, data management, security,
            communications and much more. We take a phased approach when
            implementing this type of system, this way we can ensure that your
            business never suffers downtime, even whilst migrating away from
            your current setup.
          </p>
        </div>
      </div>
    </div>

    <div class="container-fluid bg-pink mb-5">
      <div class="container">
        <div class="row">
          <div class="col-md-8 mx-auto">
            <div class="row py-5">
              <div class="col-12 col-md my-auto">
                <h1 class="text-white">ALL TOGETHER NOW...</h1>
                <p class="text-white">
                  Looking to bring all your systems under the one roof?
                </p>
              </div>
              <div class="col-12 col-md-auto my-auto">
                <router-link
                  class="btn btn-light btn-lg shadow"
                  to="/send-smoke-signal"
                >
                  <i class="far fa-arrow-right me-2"></i>Let's go!
                </router-link>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="container">
      <div class="row text-center">
        <div class="col">
          <h4 class="fw-bolder header-spacing moving-gradient-text mb-5">
            ONE PLATFORM TO REPLACE THEM ALL
          </h4>
        </div>
      </div>

      <div class="row text-center">
        <div class="col-md-6 mb-5 my-auto">
          <div class="card border-0 bg-light shadow-sm">
            <div class="card-body">
              <h2 class="fw-bold moving-gradient-text mb-4">FEATURES</h2>
              <i class="far fa-rocket fa-3x text-pink mb-4"></i>
              <ul class="list-unstyled">
                <li class="fw-bold mb-2">Event Management</li>
                <li class="fw-bold mb-2">Booking Engine</li>
                <li class="fw-bold mb-2">Contact Management</li>
                <li class="fw-bold mb-2">Contact or Client Portals</li>
                <li class="fw-bold mb-2">Video Conferencing</li>
                <li class="fw-bold mb-2">Email Marketing</li>
                <li class="fw-bold mb-2">SMS Marketing</li>
                <li class="fw-bold mb-2">Social Integrations</li>
                <li class="fw-bold mb-2">Lead Generation</li>
                <li class="fw-bold mb-2">Customer Lifecycle</li>
              </ul>
            </div>
          </div>
        </div>
        <div class="col-md-6 mb-5 my-auto">
          <p>
            Using a multitude of platforms, apps, spreadsheets and other data
            recording mechanisms can be expensive in many ways. To ensure your
            business has the everything it needs in the one platform.
          </p>
          <p>
            Lorem ipsum dolor sit amet, consectetur adipiscing elit. Sed tempus
            mi in turpis convallis, a tempor lectus scelerisque. Sed pretium
            ultricies libero, at finibus arcu fermentum id. Suspendisse sagittis
            quis lacus vitae posuere. Praesent rhoncus elit sit amet nisi
            tempor, eu vestibulum lorem ultrices. Vestibulum eget purus nulla.
            Vestibulum eu nibh congue, suscipit libero eu, sollicitudin ipsum.
            Curabitur ornare, velit eget egestas porta, mi enim porta urna, at
            luctus quam diam id arcu. Maecenas efficitur nibh quis aliquam
            tempor. Proin dapibus sit amet tortor sed ultricies. In ultrices
            diam eget est malesuada eleifend. Sed lobortis augue non turpis
            elementum porta. Nunc tincidunt eros libero, eget varius urna semper
            eget. Duis eu molestie magna, ut lacinia libero. Suspendisse
            tincidunt posuere faucibus.
          </p>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {};
</script>

<style></style>
